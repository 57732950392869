import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';
import { DeepLoop } from '@/common/deepLoop/deepLoop';


export class BigScreenGovernment extends BasicsClass {
    public getRegion(cb: Function): void {
        this.BasicGet('/animal/web/bigScreenForCountry/countryGroupCode', {}, false, true, cb)
    }

    public getAllot(groupCode: number, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenForCountry/mobilizeForInAndOut', { groupCode }, false, true, cb)
    }

    public getHarmless(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenForCompany/harmlessCount', form, false, true, cb)
    }

    public getCreditLoan(groupCode: number, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenGovernment/getCreditRatio', { groupCode }, false, true, cb)
    }

    public getInsurance(groupCode: number, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenGovernment/getPolicyInsuranceRatio', { groupCode }, false, true, cb)
    }

    public getMarket(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenForCompany/animalPrevention', form, false, true, cb)
    }

    public getSysFarm(groupCode: number, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenGovernment/getSysFarm', { groupCode }, false, true, cb)
    }
    public getSysFarm2(cb: Function): void {
        this.BasicGet('/animal/web/bigScreenInsurance/insuranceGroupCode', {}, false, true, cb)
    }
    public getSysFarm3(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenInsurance/getSysFarmNew', form, false, true, cb)
    }
    public getMapData(groupCode: number, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenGovernment/getMapData', { groupCode }, false, true, cb)
    }
    public getpigCattleAndSheepSelection(groupCode: any, farmId: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/pigCattleAndSheepSelection', { groupCode: groupCode, farmId: farmId }, false, true, cb)
    }
}