



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";
import { BigData } from "@/common/Views/bigData/bigData";
@Component
export default class HelloWorld extends Vue {
  @Prop() chickRegion!: string;

  private timecheck: number = 1;
  private from: from = {
    today: "",
    currentWeek: "",
    currentMonth: "",
    id: "",
  };
  private datas: any = [];
  private created(): void {
    // this.days();
  }

  private router(): void {
    this.$router.push({
      path: "/temperatureInfo",
    });
  }
  @Watch("chickRegion")
  private change(): void {
    this.from.id = this.chickRegion;

    this.getabnormalTempCountForZf();
  }
  private echarts(): void {
    if (this.$refs.chart) {
      let myChart: any = this.$refs.chart;
      myChart = echarts.init(<HTMLCanvasElement>myChart);
      let datay = [];
      let datax = [];
      let len = this.datas.length;
      for (let i = 0; i < len; i++) {
        datax.push(this.datas[i].name);
        datay.push(this.datas[i].count);
      }

      let option = {
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br/>异常体温数量: {c}头",

          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: datax,
          axisLabel: {
            color: "white",
            interval: 0,
            fontSize: 10,
            formatter: function (value) {
              return value.substr(0, 2).split("").join("\n");
            },
          },
          axisLine: {
            lineStyle: {
              color: "#93b5ff",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "white",
          },
          splitLine: {
            show: false,
          },
          name: "单位(头)",
          nameLocation: "end",
          minInterval: 1,
          splitNumber: 2,
          nameTextStyle: {
            fontSize: 10,
            align: "right",
            color: "rgba(255, 255, 255, 1)",
            verticalAlign: "top",
            lineHeight: 0,
          },
          axisLine: {
            lineStyle: {
              color: "#93b5ff",
            },
          },
        },
        grid: {
          left: "8%",
          right: "0%",
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            data: datay,
            type: "bar",
            // showBackground: true,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            itemStyle: {
              color: "#CE9E4D",
            },
            barWidth: 8,
          },
        ],
      };
      myChart.setOption(option);
    }
  }

  private typeShow: number = 1;

  private getabnormalTempCountForZf(): void {
    new BigData().getabnormalTempCountForZf((data) => {
      this.datas = data.data.abnormalTempCountForZf;
      this.echarts();
    }, this.from);
  }
  private handleType(nub: number): void {
    console.log(nub);
    this.typeShow = nub;
  }

  // private days(): void {
  //   this.from.currentMonth = "";
  //   this.from.currentWeek = "";
  //   let a = Number(new Date().getMonth()) + 1;

  //   this.timecheck = 1;
  //   this.from.today =
  //     new Date().getFullYear() + "-" + a + "-" + new Date().getDate();
  //   this.getabnormalTempCountForZf();
  // }
  // private months(): void {
  //   this.from.today = "";
  //   this.from.currentWeek = "";
  //   let a = Number(new Date().getMonth()) + 1;

  //   this.timecheck = 3;
  //   this.from.currentMonth = new Date().getFullYear() + "-" + a;

  //   this.getabnormalTempCountForZf();
  // }
  // private weeks(): void {
  //   this.from.today = "";
  //   this.from.currentMonth = "";
  //   this.timecheck = 2;
  //   this.from.currentWeek = 1;
  //   this.getabnormalTempCountForZf();
  // }
}
