






























import {Component, Prop, Vue,Watch} from "vue-property-decorator";
import echarts from 'echarts'
import { BigScreenGovernment } from "@/common/Views/bigScreenGovernment/bigScreenGovernment"

@Component
export default class HelloWorld extends Vue {

    @Prop() region!: number;

    private bigScreenGovernment: BigScreenGovernment = new BigScreenGovernment()

    private listName = []
    private listDate = []
    private selected = {}

    private mounted(): void {
        this.listDate = []
        this.listName = []
        this.init()
        // this.getDeta()
    }

    @Watch('region')
    private getRegion(): void {
        this.listDate = []
        this.listName = []
        this.init()
        this.getDeta()
    }

    private getDeta(): void {
        this.bigScreenGovernment.getCreditLoan(this.region, (res: any) => {
            console.log(res)
             let Data = res.data.data
             console.log(Data)
            for(let i in Data){
                this.listName.push(Data[i].name)
                this.listDate.push({
                    value: Data[i].count,
                    name: Data[i].name
                })
                
                if(Data[i].count == 0){
                    this.selected[Data[i].name] = false
                }else{
                    this.selected[Data[i].name] = true
                } 
                if(i == (Data.length - 1)){
                    this.init()
                }
            }
        })
    }
    
    private init(): void {
        if(this.$refs.chart){
            let chartBox = this.$refs.chart

            let myChart = echarts.init(<HTMLCanvasElement>chartBox)

            let colorList = [
                '#06A7D9', '#0353D6', '#826DDB', '#1D37BC', '#FFD700', '#FFC125', '#04CBBB', '#FFC1C1', '#FFFF00', '#63B8FF'
            ]

            let option: any = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}<br/>养殖场信贷数量 : {c}个<br/>{a} : {d}%'
                },
                legend: {
                    orient: 'vertical',
                    // left: 'right',
                    // right : '10%',
                    left: '65%',
                    top: '5%',
                    align: 'left',
                    height: '80%',
                    // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
                    data: this.listName,
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: 'rgba(255, 255, 255, 1)',
                        fontSize: 14,
                        borderRadius: [10, 10, 10, 10]
                    },
                    borderRadius: 20,
                    type: 'scroll',
                    // show: false
                    selected: this.selected
                },
                series: [
                    {
                        name: '养殖场信贷占比',
                        type: 'pie',
                        radius: '60%',
                        center: ['35%', '46%'],
                        avoidLabelOverlap: true,
                        label: {
                            show: true,
                            position: 'outer',
                            color: '#fff',
                            margin: '25%',
                            fontSize: 14,
                            formatter: (value) => {
                                return value.name.substr(0, 2);
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: '#fff'
                            }
                        },
                        // data: [
                        //     {value: 335, name: '直接访问'},
                        //     {value: 310, name: '邮件营销'},
                        //     {value: 234, name: '联盟广告'},
                        //     {value: 135, name: '视频广告'},
                        //     {value: 1548, name: '搜索引擎'}
                        // ],
                        data: this.listDate,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ],
                color: colorList
            }

            myChart.setOption(option)
        }
        
    }
}
