






















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";
import axios from "axios";
import { BigScreenGovernment } from "@/common/Views/bigScreenGovernment/bigScreenGovernment";

@Component
export default class HelloWorld extends Vue {
  @Prop() region!: number;

  private bigScreenGovernment: BigScreenGovernment = new BigScreenGovernment();

  private nameList = [];
  private harmlessList = [];

  private form: any = {
    today: "",
    currentMonth: "",
    currentWeek: "",
    id: "",
  };

  private mounted(): void {
    this.nameList = [];
    this.harmlessList = [];
    this.init();
    this.form.today = new Date()
      .toLocaleDateString()
      .replace("/", "-")
      .replace("/", "-");
    // this.getDeta()
  }

  @Watch("region")
  private getRegion(): void {
    this.nameList = [];
    this.harmlessList = [];
    this.init();
    this.getDeta();
  }

  private getDeta(): void {
    this.nameList = [];
    this.harmlessList = [];
    this.form.id = this.region;
    this.bigScreenGovernment.getHarmless(this.form, (res: any) => {
      let Data = res.data.harmlessCount;
      for (var i in Data) {
        this.nameList.push(Data[i].name);
        this.harmlessList.push(Data[i].count);
        if (<any>i == Data.length - 1) {
          this.init();
        }
      }
    });
  }

  private handleTime(type: number): void {
    this.form.today = "";
    this.form.currentMonth = "";
    this.form.currentWeek = "";
    if (type == 1) {
      // console.log(new Date().toLocaleDateString())
      this.form.today = new Date()
        .toLocaleDateString()
        .replace("/", "-")
        .replace("/", "-");
    } else if (type == 2) {
      this.form.currentWeek = "1";
    } else if (type == 3) {
      // console.log(new Date().toLocaleDateString().substr(0, 7))
      this.form.currentMonth = new Date()
        .toLocaleDateString()
        .replace("/", "-")
        .split('/')[0]
    }
    this.getDeta();
  }

  private init(): void {
    if (this.$refs.chart) {
      let chartBox = this.$refs.chart;

      let myChart = echarts.init(<HTMLCanvasElement>chartBox);

      let option: any = {
        tooltip: {
          trigger: "axis",
          right: "0%",
        },
        // legend: {
        //     data: ['邮件营销', '联盟广告'],
        //     top: '2%',
        //     left: '45%',
        //     textStyle: {
        //         color: '#fff',
        //         fontSize: 11
        //     }
        // },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "15%",
          top: "15%",
          containLabel: true,
        },
        minInterval: 1,

        xAxis: [
          {
            type: "category",
            data: this.nameList,
            // data: this.xAxis,
            axisLine: {
              lineStyle: {
                color: "#1F63A3",
              },
            },
            axisLabel: {
              fontSize: 10,
              color: "#93B5FF",
              formatter: (value) => {
                return (value.substr(0, 2)).split("").join("\n");
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "单位(头)",
            nameLocation: "end",
            type: "value",
            // interval: 1,
            // min: 'dataMin',
            // max: 'dataMax',
            axisLine: {
              lineStyle: {
                color: "rgba(31, 99, 163, 1)",
              },
            },
            axisLabel: {
              fontSize: 10,
              color: "#93B5FF",
            },
            nameTextStyle: {
              fontSize: 10,
              align: "right",
              color: "#93B5FF",
              verticalAlign: "top",
              lineHeight: 0,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              inside: true,
              length: 3,
              lineStyle: {
                color: "rgba(19, 60, 123, 1)",
              },
            },
          },
        ],
        series: [
          {
            name: "无害化处理数量",
            type: "line",
            stack: "无害化处理数量",
            data: this.harmlessList,
            showSymbol: false,
            itemStyle: {
              color: "#3554B1",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#3554B1",
                },
                {
                  offset: 1,
                  color: "transparent",
                },
              ]),
            },
          },
        ],
      };

      myChart.setOption(option);
    }
  }
}
