












































































import {Component, Prop, Vue,Watch} from "vue-property-decorator";
import echarts from 'echarts'
import { BigScreenGovernment } from "@/common/Views/bigScreenGovernment/bigScreenGovernment"

@Component
export default class HelloWorld extends Vue {

    @Prop() region!: number;

    private bigScreenGovernment: BigScreenGovernment = new BigScreenGovernment()

    private form: any = {
        defense: '0',
        id: ''
    }

    private list = []

    private mounted(): void {
        // this.getDeta()
    }

    private handleDefense(type: string): void {
        this.form.defense = type
        this.getDeta()
    }

    @Watch('region')
    private getRegion(): void {
        this.getDeta()
    }

    private getDeta(): void {
        this.form.id = this.region
        this.bigScreenGovernment.getMarket(this.form, (res: any) => {
            console.log(res)
            this.list = res.data.animalPrevention
        })
    }

}
