








































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";
import { BigScreenGovernment } from "@/common/Views/bigScreenGovernment/bigScreenGovernment";

@Component
export default class HelloWorld extends Vue {
  @Prop() region!: number;

  private bigScreenGovernment: BigScreenGovernment = new BigScreenGovernment();

  private nameList = [];
  private foldList = [];
  private calloutList = [];

  private mounted(): void {
    this.nameList = [];
    this.foldList = [];
    this.calloutList = [];
    this.init();
    // this.getDeta()
  }

  @Watch("region")
  private getRegion(): void {
    this.nameList = [];
    this.foldList = [];
    this.calloutList = [];
    this.init();
    this.getDeta();
  }

  private getDeta(): void {
    this.bigScreenGovernment.getAllot(this.region, (res: any) => {
      console.log(res);
      let Data = res.data;
      for (var i in Data) {
        this.nameList.push(Data[i].groupName);
        this.foldList.push(Data[i].countIn);
        this.calloutList.push(Data[i].countOut);
        if (<any>i == Data.length - 1) {
          this.init();
        }
      }
    });
  }

  private init(): void {
    if (this.$refs.chart) {
      let chartBox = this.$refs.chart;

      let myChart = echarts.init(<HTMLCanvasElement>chartBox);

      let option: any = {
        tooltip: {
          trigger: "axis",
          right: "0%",
        },
        legend: {
          data: ["调入", "调出"],
          top: "2%",
        //   left: "55%",
          right: '10%',
          textStyle: {
            color: "#fff",
            fontSize: 11,
          },
        },
        grid: {
          left: "6%",
          right: "4%",
          bottom: "0%",
          top: "25%",
          containLabel: true,
        },
        minInterval: 1,

        xAxis: [
          {
            type: "category",
            data: this.nameList,
            // data: this.xAxis,
            axisLine: {
              lineStyle: {
                color: "#1F63A3",
              },
            },
            axisLabel: {
              fontSize: 10,
              color: "#93B5FF",
              formatter: (value) => {
                // console.log(value)
                // return value;
                
                return (value.substr(0, 2)).split("").join("\n")
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "单位(头)",
            nameLocation: "end",
            type: "value",
            // interval: 1,
            // min: 'dataMin',
            // max: 'dataMax',
            axisLine: {
              lineStyle: {
                color: "rgba(31, 99, 163, 1)",
              },
            },
            axisLabel: {
              fontSize: 10,
              color: "#93B5FF",
            },
            nameTextStyle: {
              fontSize: 10,
              align: "right",
              color: "#93B5FF",
              verticalAlign: "top",
              lineHeight: 0,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              inside: true,
              length: 3,
              lineStyle: {
                color: "rgba(19, 60, 123, 1)",
              },
            },
          },
        ],
        series: [
          {
            name: "调入",
            type: "line",
            stack: "调入",
            data: this.foldList,
            itemStyle: {
              color: "#C27F00",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#C27F00",
                },
                {
                  offset: 1,
                  color: "transparent",
                },
              ]),
            },
          },
          {
            name: "调出",
            type: "line",
            stack: "调出",
            data: this.foldList,
            itemStyle: {
              color: "#16FAD3",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#16FAD3",
                },
                {
                  offset: 1,
                  color: "transparent",
                },
              ]),
            },
          },
        ],
      };

      myChart.setOption(option);
    }
  }
}
